import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import NewlyDiagnosedSubmenu from "../../components/template-partials/newly-diagnosed/newly-diagnosed-submenu"
import headingBg from "../../images/2.0-headerbg.jpg"
import headingRound from "../../images/newly-diagnosed-nav-image.jpg"

import PersonSelector from "../../components/template-partials/global-components/person-selector/personSelector"

import {UpNextCard, ExploreCard} from "../../components/template-partials/global-components/footer-cards"



import pediatrician from "../../images/2.4.01_pediatrician2.png";
import nursePractioner from "../../images/2.4.02_nursepractioner.png";
import resident from "../../images/2.4.06_resident.png";
import fellow from "../../images/2.4.03_Fellow.png";
import pharmacist from "../../images/2.4.04_pharmacist.png";
import oncologyNurse from "../../images/2.4.03_oncology_nurse_male.png";

import pediatricianThumb from "../../images/2.4.01_pediatrician2thumb.jpg";
import pharmacistThumb from "../../images/2.4.04_pharmacistThumb.jpg";
import fellowThumb from "../../images/2.4.05_fellowThumb.png";
import residentThumb from "../../images/2.4.06_residentThumb.png";
import oncologyNurseThumb from "../../images/2.4.03_oncology_nurse_malethumb.jpg";
import nursePractionerThumb from "../../images/2.2.02_appThumb.jpg";

import pallativeCare from "../../images/2.4.0xpallativecareteam.jpg";
import childLife from "../../images/2.4.0xchildlife.jpg";
import caseManger from "../../images/2.4.0xCasemanager.jpg";
import speechCopy from "../../images/2.4.0xspeechCopy.jpg";
import speech from "../../images/2.4.0xspeech.jpg";
import dietitian from "../../images/2.4.0xdietitian.jpg";

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const TreatmentTeamPage = () => {
  return (
    <Layout>
      <SEO title="Your Neuroblastoma Healthcare Team" description="Becoming familiar with the oncology treatment team can help you and your child feel at ease. Read more to understand each team member's role in your child’s care." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Getting to Know Your Treatment Team<">
        <h1 className="section__heading section__heading--green h1">Getting <em>to</em> Know Your Treatment Team</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Newly Diagnosed`}>
              <NewlyDiagnosedSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Meeting your child’s healthcare team</h2>
                <p>Your child and family may see a lot of new faces during treatment. Hospitals strive to provide comprehensive care that requires specialized and trained professionals. Each member of the team works hard to meet the specific needs of your child. Depending on your child’s requirements and the hospital program, your care team may include some or all of the following people.</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Oncology healthcare team members</h3>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Select a healthcare team member below to learn more about their role.</h4>
          </div>
        </div>
        <PersonSelector>
          <PersonSelector.Person key="1" name="Attending Physician" thumb={pediatricianThumb} fullSize={pediatrician}>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Attending Physician (Pediatric Oncologist)</h4>
            <p>The attending physician is a doctor who has completed training to provide specialty care, such as cancer care. They supervise the care team and direct the care plan for your child.</p>
          </PersonSelector.Person>
          <PersonSelector.Person key="2" name="Advanced Practice Provider" thumb={nursePractionerThumb} fullSize={nursePractioner}>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Advanced Practice Provider (APP): Nurse Practitioner or Physician’s Assistant</h4>
            <p>An <Link rel="glossary" to="/support-and-community/glossary/#advanced-practice-provider-app-nurse-practitioner-or-physician-s-assistant">APP</Link> has advanced training to help diagnose and care for your child. The APP’s duties include ordering diagnostic and  laboratory testing, prescribing medication, providing counsel and education, and coordinating care for your child.</p>
          </PersonSelector.Person>
          <PersonSelector.Person key="3" name="Oncology Nurse" thumb={oncologyNurseThumb} fullSize={oncologyNurse}>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Oncology Nurse</h4>
            <p>An <Link rel="glossary" to="/support-and-community/glossary/#oncology-nurse">oncology nurse</Link> is trained to care for patients in the hospital and the clinic. Nurses have many duties, including administering medication, checking <Link rel="glossary" to="/support-and-community/glossary/#vital-signs">vital signs</Link>, communicating with the care team, and providing education. Many hospitals assign a primary nurse who you see regularly during treatment.</p>
          </PersonSelector.Person>
          <PersonSelector.Person key="4" name="Pharmacist" thumb={pharmacistThumb} fullSize={pharmacist}>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Pharmacist</h4>
            <p>A <Link rel="glossary" to="/support-and-community/glossary/#pharmacist">pharmacist</Link> has advanced training to prepare medicine and nutritional support for your child. A pharmacist may also provide education about the medicines your child receives.</p>
          </PersonSelector.Person>
          <PersonSelector.Person key="5" name="Fellow" thumb={fellowThumb} fullSize={fellow}>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Fellow</h4>
            <p>A <Link rel="glossary" to="/support-and-community/glossary/#fellow">fellow</Link> is a doctor who has completed medical school and a residency, and who is now receiving specialty training in pediatric oncology.</p>
          </PersonSelector.Person>
          <PersonSelector.Person key="6" name="Resident" thumb={residentThumb} fullSize={resident}>
            <h4 className="h4 tc-viridian jost-med lg_mt-2">Resident</h4>
            <p>A <Link rel="glossary" to="/support-and-community/glossary/#resident">resident</Link> is a doctor who has completed medical school and now is receiving specialty training in pediatrics. These doctors are closely supervised by the attending physician.</p>
          </PersonSelector.Person>
        </PersonSelector>
      </Section>

      <Section id="team">
        <div className="row row--inner">
          <div className="columns"><h3 className="h2 tc-barney jost-med">Additional oncology healthcare team members</h3></div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns large-6 small-12">
            <div className="row">
              <div className="columns shrink mt-1">
                <img src={pallativeCare} alt="Palliative care team"/>
              </div>
              <div className="columns">
              <h5 className="h5 tc-viridian jost-med">Palliative Care Team</h5>
              <p>This is a separate team of doctors, nurse practitioners, nurses, social workers, and chaplains who specialize in managing symptoms and providing support during a serious illness. They work closely with your care team to improve quality of life for your child and family.</p>
              </div>
            </div>
          </div>
          <div className="columns large-6 small-12">
            <div className="row">
              <div className="columns shrink mt-1">
                <img src={childLife} alt="Child Life Specialist"/>
              </div>
              <div className="columns">
              <h5 className="h5 tc-viridian jost-med">Child Life Specialist</h5>
              <p>A child life specialist has specialty training in child development. They help prepare your child for procedures, treatment, and coping with cancer.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns large-6 small-12">
            <div className="row">
              <div className="columns shrink mt-1">
                <img src={dietitian} alt="Registered Dietitian"/>
              </div>
              <div className="columns">
              <h5 className="h5 tc-viridian jost-med">Registered Dietitian</h5>
              <p>A registered dietitian is trained to help the team evaluate your child’s nutritional needs and growth. They also provide education and nutritional support for your child.</p>
              </div>
            </div>
          </div>
          <div className="columns large-6 small-12">
            <div className="row">
              <div className="columns shrink mt-1">
                <img src={caseManger} alt="Case Manager"/>
              </div>
              <div className="columns">
              <h5 className="h5 tc-viridian jost-med">Case Manager</h5>
              <p>A case manager works with the team to coordinate any home care or therapy needs after you return home from a hospital visit.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns large-6 small-12">
            <div className="row">
              <div className="columns shrink mt-1">
                <img src={speech} alt="Physical/Occupational/Speech Therapist"/>
              </div>
              <div className="columns">
              <h5 className="h5 tc-viridian jost-med">Physical/Occupational/Speech Therapist</h5>
              <p>These are people with specialty training to work with your child to maintain or recover developmental skills.</p>
              </div>
            </div>
          </div>
          <div className="columns large-6 small-12">
            <div className="row">
              <div className="columns shrink mt-1">
                <img src={speechCopy} alt="Physical/Occupational/Speech Therapist"/>
              </div>
              <div className="columns">
              <h5 className="h5 tc-viridian jost-med">Social Worker</h5>
              <p>A social worker is trained to help your child and family cope with the cancer journey through counseling, emotional support, and connection to pertinent resources on an individual and community level.</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>

      <UpNextCard topic="Questions To Ask Your Doctor" title="Questions to ask your child’s doctor about neuroblastoma" lead="Use the question builder to help prepare for conversations about your child's care." url="/diagnosis/doctor-discussion-guide/"/>

      <ExploreCard title="Learn more about your child's treatment for high-risk neuroblastoma" lead="See what therapies are used to treat high-risk neuroblastoma." url="/treatment/high-risk-neuroblastoma/"/>


      </InteriorPageFooter>

    </Layout>
  )
}

export default TreatmentTeamPage
