import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types"

const PersonSelector = (props) => {

  const [active, setActive] = useState(1);

  useEffect(() => {
    setActive(1);
  },[]);

  const handleActive = (key) => {
    console.log();
    setActive(parseInt(key));
  }

  return (
    <>
      <div className="row row--inner align-center lg_mt-2 person-selector">
        <div className="columns large-7 small-12">
          <div className="row">
            <div className="columns large-8 small-12">
              <div className="row person-selector__people align-center">
                {React.Children.map(props.children, child => {
                  return (
                    <div className={active === parseInt(child.key)? 'columns large-4 small-4 text-center lg_mb-2 isActive mt-1' : 'columns large-4 small-4 text-center lg_mb-2 mt-1'}>
                      <button onClick={() => handleActive(child.key)}>
                        <img src={child.props.thumb} alt={child.props.name} style={{maxWidth: `92px`}} height="auto"/>
                        <span>{child.props.name}</span>
                      </button>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            {React.Children.map(props.children, child => {
              return(
                <div className={active === parseInt(child.key) ? `columns` : `visually-hidden`}>
                  {child.props.children}
                </div>
              )
            })}
          </div>
        </div>
        {React.Children.map(props.children, child => {
          return(
            <div className={active === parseInt(child.key) ? `columns small-6 large-5 mt-2 text-center` : `visually-hidden`}>
              <img src={child.props.fullSize} alt={child.props.name}/>
            </div>
          )
        })}
      </div>
    </>
  )
}


PersonSelector.Person = ({ children }) => children

PersonSelector.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PersonSelector
